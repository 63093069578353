@charset "UTF-8";

/* SuisseIntl-regular */
@font-face {
  font-family: 'suisse_intlregular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/iupics/fonts/SuisseIntl-Regular.otf');
  /* IE9 Compat Modes */
  src:
    local('SuisseIntl Regular'),
    local('SuisseIntl-Regular'),
    url('/assets/iupics/fonts/suisseintl-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/iupics/fonts/suisseintl-regular-webfont.ttf') format('truetype'),
    url('/assets/iupics/fonts/suisseintl-regular-webfont.woff2') format('woff2'),
    url('/assets/iupics/fonts/suisseintl-regular-webfont.woff') format('woff'),
    url('/assets/iupics/fonts/suisseintl-regular-webfont.svg#suisse_intlregular') format('svg');
  /* Legacy iOS */
}
/* SuisseIntl-regular - Medium  Bold */
@font-face {
  font-family: 'suisse_intlregular';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('/assets/iupics/fonts/SuisseIntl-Medium.otf');
  /*IE9 Compat Modes */
  src:
    local('SuisseIntl Medium'),
    local('SuisseIntl-Medium'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.eot'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.ttf') format('truetype'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.woff2') format('woff2'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.woff') format('woff'),
    url('/assets/iupics/fonts/suisseintl-medium-webfont.svg#suisse_intlmedium') format('svg');
}
/* SuisseIntl-regular - regular italic */
@font-face {
  font-family: 'suisse_intlregular';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/iupics/fonts/SuisseIntl-RegularItalic.otf');
  /*IE9 Compat Modes */
  src:
    local('SuisseIntl Medium'),
    local('SuisseIntl-RegularItalic'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.eot'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.ttf') format('truetype'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.woff2') format('woff2'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.woff') format('woff'),
    url('/assets/iupics/fonts/suisseintl-regularitalic-webfont.svg#suisse_intlregular_italic') format('svg');
}

/*#region reset */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  // Scrollbar
  // Firefox
  scrollbar-color: var(--global-scrollbar-bg-color-thumb) var(--global-scrollbar-bg-color);
  scrollbar-width: var(--global-scrollbar-wd-firefox);
  // Others
  &::-webkit-scrollbar {
    width: var(--global-scrollbar-wd);
    height: var(--global-scrollbar-wd);
    background: var(--global-scrollbar-bg-color);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--global-scrollbar-br-thumb);
    background: var(--global-scrollbar-bg-color-thumb);
  }
}

html,
body,
.app {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  position: fixed;
  overflow: hidden;
  font-size: 12px;
  font-family: var(--font-family);
}

body,
input {
  font-size: 12px;
  font-family: var(--font-family) !important;
}

body {
  &.click-event-disabled {
    pointer-events: none;
  }
  width: 100vw;
  overflow-y: hidden; //https://books.google.be/books?id=0Q1NhNXf5TYC&pg=PA330&lpg=PA330&dq=scroll+ios+webapp&source=bl&ots=QTC8AkS5Pp&sig=ACfU3U00oOYD6zAgULIKbK20pmmXluiKmw&hl=en&sa=X&ved=2ahUKEwjruNWFqZjqAhVNCuwKHeNqCFsQ6AEwBnoECAoQAQ#v=onepage&q=scroll%20ios%20webapp&f=false
  -webkit-overflow-scrolling: touch;
  background-color: var(--global-bg-color-1);
  /* Désactivation du pull to refresh pour chrome android */
  overscroll-behavior: none;

  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: var(--input-ft-color-placeholder);
  }
}
iu-grid-ui > div.p-col-nopad.p-offset-0 {
  &.p-col-1,
  &.p-col-2,
  &.p-col-3,
  &.p-col-4,
  &.p-col-5,
  &.p-col-6,
  &.p-col-7,
  &.p-col-8,
  &.p-col-9,
  &.p-col-10,
  &.p-col-11,
  &.p-col-12 {
    float: left;
  }
}
.app {
  position: relative;
  overscroll-behavior: contain;
  overflow: auto;
}

.p-component,
.p-component * {
  font-size: 12px;
}

[class^='icon-'],
[class*=' icon-'] {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*#endregion*/

/*#region index */
body {
  .indexLoaderMsg {
    padding: 15vh 20px 20px 20px;
    text-align: center;
    width: 100%;

    #loadingTooLong {
      &.hidden {
        display: none;
      }

      h1#loadingIndexTooLong {
        font-size: 20px;
        font-weight: normal;
        font-family: var(--font-family, sans-serif);
        margin: 10px;
        padding: 10px;
        color: var(--index-ft-color-too-long-title, black);
      }
      a#btnReloadIndex {
        font-weight: normal;
        background-color: var(--index-bg-color-too-long-btn, #f7941d);
        border: 1px solid var(--index-bd-color-too-long-btn, #f7941d);
        border-radius: var(--index-br-too-long-btn, 5px);
        padding: 4px 14px;
        width: auto !important;
        margin-left: 4px;
        margin-right: 5px;
        cursor: pointer;
        font-family: var(--font-family, sans-serif);
        font-size: 12px;
        color: var(--index-ft-color-too-long-btn, #fff) !important;
        text-decoration: none;
      }
    }
  }
}
/*#endregion*/

.bladeContent > .iupics-blade-content:last-child {
  border-top-right-radius: var(--blade-br);
}
.iupics-blade-content-first.gridCollapsed {
  border-right: 2px solid var(--blade-bd-color-grid-collapsed);
}
.p-contextmenu {
  background-color: var(--p-context-menu-bg-color);

  a.p-menuitem-link {
    color: var(--p-context-menu-ft-color-menu-item-link);
  }
}

body {
  .ui-corner-all {
    border-radius: var(--global-border-radius);
  }
  .p-toolbar.ui-corner-all {
    border-radius: var(--p-toolbar-br-ui-corner-all);
  }

  a {
    text-decoration: none;
    color: var(--link-ft-color);
  }
  .ui-helper-reset {
    line-height: normal;
  }

  .p-disabled,
  .p-component:disabled {
    border-color: var(--p-disabled-bg-color);
    background-color: var(--p-disabled-bd-color);
  }
  .p-inputtext {
    user-select: text !important;
    font-size: 12px;
    color: var(--input-ft-color);
    background: var(--input-bg-color);
    padding: 0.5em;
    border: 1px solid var(--input-bd-color);
    appearance: none;
    height: 26px;

    &:enabled:not(:read-only):not(.ui-state-error) {
      &:hover {
        border-color: var(--input-bd-color-hover);
      }
      &:focus {
        border-color: var(--input-bd-color-focus);
        outline: 0 none;
        box-shadow: none;
      }
    }
  }

  select {
    font-size: 12px;
    color: var(--input-ft-color);
    background: var(--input-bg-color);
    padding: 0.429em;
    border: 1px solid var(--input-bd-color);
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--input-br);
    background-image: url('/assets/iupics/img/icon-select.svg');
    background-position: right 8px center;
    background-size: 8 13px;
    background-repeat: no-repeat;

    &:hover {
      border-color: var(--input-bd-color-hover);
    }
    &:active,
    &:focus {
      border-color: var(--input-bd-color-focus);
    }

    option {
      padding: 3px 5px;
    }
  }

  // todo: vérifier le css de p-checkbox suite au remplacement par iu-checkbox-ui
  .p-checkbox {
    height: fit-content;

    .p-checkbox-box {
      &.p-highlight {
        background-color: var(--checkbox-bg-color-highlight);
        border-color: var(--checkbox-bd-color-highlight);
      }
    }
  }

  .p-checkbox .p-checkbox-box,
  .ag-cell input[type='checkbox'] {
    transition:
      background-color 0.2s,
      border-color 0.2s;
  }

  .p-checkbox .p-checkbox-box {
    &:not(.p-disabled):hover {
      color: var(--checkbox-ft-color-hover);
    }
    &.p-disabled,
    &.p-disabled .p-checkbox-icon:not(.pi-check) {
      color: var(--checkbox-ft-color-icon-disabled) !important;
      background-color: transparent;
      ::before {
        background-color: var(--checkbox-bg-color-icon-disabled);
      }
    }
    .p-checkbox-icon {
      position: relative;
      width: calc(var(--checkbox-ft-size) + 2px);
      font-family: var(--font-family-icon) !important;
      color: var(--checkbox-ft-color-icon);
      font-size: var(--checkbox-ft-size);

      &.pi-check::before {
        content: '\e918';
        background-color: var(--checkbox-bg-color-icon);
        border-radius: var(--checkbox-br-icon);
      }
    }
    &.ui-state-focus {
      border-color: var(--checkbox-bd-color-focus);
      background-color: var(--checkbox-bg-color-focus);
      color: var(--checkbox-ft-color-icon-focus);
    }
  }

  .p-checkbox-icon:not(.icon-checkbox-tri-false):not(.icon-checkbox-checked)::before {
    content: '\e944';
    background-color: var(--checkbox-bg-color);
    border-radius: var(--checkbox-br-icon);
  }
  .p-checkbox-label {
    margin: 0 0 0 0.5em;
  }

  .p-autocomplete {
    width: 100%;
    box-shadow: none;
    position: relative;
    display: inline-block;

    &.p-autocomplete-multiple .p-autocomplete-multiple-container {
      padding: 0.2145em 0.429em;
      flex-direction: row;
      display: flex;

      @media screen and (min-width: 780px) {
        min-width: 128px;
      }

      &:not(.p-disabled) {
        &:hover {
          border-color: var(--prime-autocomplete-bd-color-multiple-container-hover);
        }
        &.ui-state-focus {
          border-color: var(--prime-autocomplete-bd-color-multiple-container-focus);
          outline: 0 none;
          box-shadow: var(--prime-autocomplete-bs-multiple-container-focus);
        }
      }
      .p-autocomplete-input-token {
        margin: 0;
        padding: 0.2145em 0;
      }
      .p-autocomplete-input-token input {
        font-family: var(--prime-autocomplete-ff-multiple-container-input-token-input);
        padding: 0;
        margin: 0;
      }
      .p-autocomplete-token {
        font-size: 12px;
        padding: 4px 5px;
        margin: 0 0.286em 0 0;
        background: var(--prime-autocomplete-bg-color-multiple-container-token);
        color: var(--prime-autocomplete-ft-color-multiple-container-token);
      }
      .p-autocomplete-token .p-autocomplete-token-icon {
        font-family: var(--font-family-icon);
        font-size: 10px;
        background-color: var(--prime-autocomplete-bg-color-multiple-container-token-icon);

        &::before {
          content: '\e941';
        }
      }
    }
    .p-autocomplete-dropdown .p-button-icon-left:before {
      content: '';
    }
  }
  .p-autocomplete-panel {
    overflow: auto;
    background: var(--prime-autocomplete-bg-color-panel);
    z-index: 9999;
    cursor: pointer;

    .p-autocomplete-items {
      padding: 0;
      margin: 5px 0;

      .p-autocomplete-list-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: none;
        color: var(--prime-autocomplete-ft-color-panel-list-item);
        background-color: var(--prime-autocomplete-bg-color-panel-list-item);
        border-radius: var(--prime-autocomplete-br-panel-list-item);
        min-height: 24.8px;
        list-style-type: none;

        &.p-highlight {
          color: var(--prime-autocomplete-ft-color-panel-list-item-highlight);
          background-color: var(--prime-autocomplete-bg-color-panel-list-item-highlight);
        }
      }
      .p-autocomplete-group {
        padding: 0.429em 0.857em;
        background-color: var(--prime-autocomplete-bg-color-panel-group);
        color: var(--prime-autocomplete-ft-color-panel-group);
      }
    }
  }
  .autocompleteRenderer {
    position: relative;
  }
  .autocompleteRenderer .p-autocomplete-panel,
  .p-autocomplete-panel {
    position: absolute;
  }
  .ui-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2.357em;
  }
  .ui-fluid .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
    border-right: 0 none;
    width: calc(100% - 2.357em);
  }
  .ui-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
    border-right: 0 none;
    width: calc(100% - 2.357em);
  }
}

/* language user profil */
p-overlaypanel.userProfile > div {
  max-height: 90vh;
  overflow: auto;
}

//for range calendar
body .apiz-p-grid {
  iu-calendar-ui.p-col-6.p-offset-0,
  iu-input-number-ui.p-col-6.p-offset-0,
  iu-input-text-ui.p-col-6.p-offset-0 {
    &.rangeTo {
      padding-right: 0;
    }
    &.rangeFrom {
      padding-left: 0;
    }
  }
}
body .iu-calendar-toolbar-ui .ui-dropdown {
  height: 26px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: -1px;
}

.search-container.p-sidebar-top.p-sidebar-active {
  padding: 0;
}

.p-sidebar-top.p-sidebar-active {
  padding: 30px 10%;
}

body {
  .p-datepicker {
    &:not(.p-datepicker-inline) {
      border: 1px solid var(--prime-datepicker-bd-color);
      -webkit-box-shadow: var(--prime-datepicker-bs);
      -moz-box-shadow: var(--prime-datepicker-bs);
      box-shadow: var(--prime-datepicker-bs);
      background-color: var(--prime-datepicker-bg-color);
      min-width: 250px;
      //z-index: 9999 !important;
    }
    &:not(.p-disabled) {
      table td a:not(.p-highlight):not(.p-highlight):hover,
      .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
        background-color: var(--prime-datepicker-bg-color-link-hover);
      }
      .p-datepicker-header {
        padding: 0.429em 0.857em 0.429em 0.857em;
        background-color: var(--prime-datepicker-bg-color-header);
        color: var(--prime-datepicker-ft-color-header);
        border-radius: var(--prime-datepicker-br-header);

        .p-datepicker-prev,
        .p-datepicker-next {
          cursor: pointer;
          color: var(--prime-datepicker-ft-color-icon);
          -moz-transition: color 0.2s;
          -o-transition: color 0.2s;
          -webkit-transition: color 0.2s;
          transition: color 0.2s;

          &:hover {
            color: var(--prime-datepicker-ft-color-icon-hover);
          }
          &:focus {
            outline: 0 none;
            color: var(--prime-datepicker-ft-color-icon-focus);
          }
        }
      }
    }

    &.p-datepicker-timeonly {
      padding: 0;
      // top: auto !important;
      background-color: var(--prime-datepicker-bg-color-timeonly);
      // min-width: auto !important;

      .p-timepicker {
        border-top: 0 none;
        padding: 8px 15px;

        & > div {
          margin-left: 0em;
        }
      }
    }
    &.p-datepicker-multiple-month .p-datepicker-group {
      border-right: 1px solid var(--prime-datepicker-bd-color-multpile-month-group);
      padding-right: 0.857em;
      padding-left: 0.857em;
      padding-top: 0;
      padding-bottom: 0;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        border-right: 0 none;
      }
    }

    table {
      font-size: 13px;
      margin: 0.857em 0 0 0;

      th {
        padding: 0.5em;
        color: var(--prime-datepicker-ft-color-table-th);
      }
      td > a,
      td > span {
        display: block;
        text-align: center;
        color: var(--prime-datepicker-ft-color-table-td);
        padding: 0.5em;
        border-radius: var(--prime-datepicker-br-table-td);
      }
      td {
        &.p-datepicker-today a {
          background-color: var(--prime-datepicker-bg-color-table-td-today-link);
          color: var(--prime-datepicker-ft-color-table-td-today-link);
        }
        & > a {
          cursor: pointer;

          &.p-highlight {
            color: var(--prime-datepicker-ft-color-table-td-link-highlight);
            background-color: var(--prime-datepicker-bg-color-table-td-link-highlight);
          }
        }
      }
    }

    .p-accordion-header {
      .p-datepicker-prev,
      .p-datepicker-next {
        cursor: pointer;
        color: var(--prime-datepicker-ft-color-accordion-header-icon);
        -moz-transition: color 0.2s;
        -o-transition: color 0.2s;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
      }
      .p-datepicker-prev span:before {
        content: '';
      }
      .p-datepicker-next span:before {
        content: '';
      }
      .p-datepicker-title {
        margin: 0;
        padding: 0;
        line-height: 1;
        color: var(--prime-datepicker-ft-color-accordion-header-title);

        select {
          margin-top: -0.35em;
          margin-bottom: 0;
        }
      }
    }
    .p-datepicker-buttonbar {
      border-top: 1px solid var(--prime-datepicker-bd-color-buttonbar);
    }
    .p-timepicker {
      border: none;
      color: var(--prime-datepicker-ft-color-timepicker);
      border-top: 1px solid var(--prime-datepicker-bd-color-timepicker);
      padding: 0.857em;

      a {
        color: var(--prime-datepicker-ft-color-timepicker-icon);
        font-size: 1.286em;

        &:hover {
          color: var(--prime-datepicker-ft-color-timepicker-icon-hover);
        }
      }
    }
    .p-monthpicker .p-monthpicker-month {
      color: var(--prime-datepicker-ft-color-monthpicker-month);

      &.p-highlight {
        color: var(--prime-datepicker-ft-color-monthpicker-month-highlight);
        background-color: var(--prime-datepicker-bg-color-monthpicker-month-highlight);
      }
    }
  }

  .p-calendar {
    &.p-calendar-w-btn {
      .p-inputtext {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 none;

        &:enabled {
          &:hover,
          &:focus {
            &:not(.ui-state-error) {
              border-right: 0 none;
            }
          }
        }
      }
      .p-datepicker-trigger.p-button {
        width: 2.357em;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

body {
  /* .p-inputtext.ng-dirty.ng-invalid,
  p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
  p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
  p-chips.ng-dirty.ng-invalid > .p-inputtext,
  p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
  p-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
  p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
  p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
  p-listbox.ng-dirty.ng-invalid .p-inputtext,
  p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
  p-spinner.ng-dirty.ng-invalid > .p-inputtext,
  p-selectbutton.ng-dirty.ng-invalid .p-button,
  p-togglebutton.ng-dirty.ng-invalid .p-button {
    border: 1px solid var(--ng-invalid-bd-color);
  } */

  /*  toolbar */
  .wd-menu-top-show {
    .p-button,
    .p-button.p-button-text-only .p-button-text,
    .p-button.p-button-text-icon-right .p-button-text,
    .p-button.p-button-icon-only {
      height: 26px;
      line-height: 26px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .p-button {
      margin: 0 4px;

      &.p-button-text-only .p-button-text {
        padding: 5px 8px;
      }
      &.p-button-icon-only {
        width: 26px;

        span {
          .p-button-icon-left {
            width: 12px;
            height: 12px;
            margin-top: -6px;
            margin-left: -6px;

            &.fa-angle-right {
              margin-left: -6px;
            }
          }
        }
        .p-button-text {
          padding: 4px;
        }
      }
      &.p-button-raised {
        box-shadow: var(--menu-top-bs-wd-menu-top-show-p-button-raised);
      }
      &.p-button-rounded {
        border-radius: var(--menu-top-br-wd-menu-top-show-p-button-rounded);
      }
    }
  }
}

body .ui-fluid .p-button-icon-only {
  width: 2.357em;
}

@media (max-width: 640px) {
  body .p-buttonset .p-button {
    margin-bottom: 1px;
    border-radius: var(--p-buttonset-p-button-br-small);
  }
}

body .iu-specific-window {
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .p-grid {
      justify-content: center;
    }
  }
  .p-scrollpanel-content:first-child {
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: content-box;
  }
  .forcedHeight {
    height: 270px;
  }
}

p.help {
  white-space: pre-wrap;
  font-style: italic;
  padding-left: 0.5em;
  &::before {
    content: '\e950';
    font-family: var(--font-family-icon) !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: var(--help-ft-color);
  }
  &.alignRight {
    padding-right: 0.5em;
    &::before {
      content: ' ';
    }
    &::after {
      content: '\e950';
      font-family: var(--font-family-icon) !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      font-size: 14px;
      color: var(--help-ft-color);
    }
  }
  &.alignCenter {
    text-align: center;
  }
}

body p {
  line-height: 1.5;
  margin: 0;
}

body .p-fieldset {
  border: 0px solid var(--fieldset-bd-color);
  background-color: var(--fieldset-bg-color);

  .p-fieldset-legend {
    display: block;
    width: 100%;

    a {
      padding: 0.571em 1em;
      border: 0;
      color: var(--fieldset-ft-color-title);
      border-bottom: 1px solid var(--fieldset-bd-color-title);
      text-transform: uppercase;
      font-size: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      background-color: var(--fieldset-bg-color-title);
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
        background-color: var(--fieldset-bg-color-title-hover);
        color: var(--fieldset-ft-color-title-hover);
        border-bottom-color: var(--fieldset-bd-color-title-hover);
      }
      .p-fieldset-toggler /*icon */ {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--fieldset-bg-color-title-btn);
        border: 1px solid var(--fieldset-bd-color-title-btn);
        color: var(--fieldset-ft-color-title-btn);
        padding: 5px 5px;
        -webkit-border-radius: var(--fieldset-br-title-btn);
        -moz-border-radius: var(--fieldset-br-title-btn);
        border-radius: var(--fieldset-br-title-btn);

        &:hover {
          background-color: var(--fieldset-bg-color-title-btn-hover);
          border-color: var(--fieldset-bd-color-title-btn-hover);
          color: var(--fieldset-ft-color-title-btn-hover);
        }
      }
      .p-fieldset-legend-text {
        padding: 0;
      }

      span.icon-next-info {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0px;
        background-color: var(--fieldset-bg-color-title-btn-icon-next-info);
        border: 1px solid var(--fieldset-bd-color-title-btn-icon-next-info);
        padding: 5px 3px 5px 6px;
        border-radius: var(--fieldset-br-title-btn-icon-next-info);
        width: 24px;
        height: 24px;

        &:hover {
          color: var(--fieldset-ft-color-title-btn-icon-next-info-hover);
          border: 1px solid var(--fieldset-bd-color-title-btn-icon-next-info-hover);
        }
      }
    }
  }
  .p-fieldset-content {
    padding: 0;
  }
}

body .iupics-blade-content {
  .contentInlineScrollPanel > iu-grid-ui > div.p-col-12.p-col-nopad {
    padding-bottom: 5rem; // avoid end of blade content overflow
  }
  .p-fieldset.p-fieldset-toggleable {
    margin-left: 0;
    margin-right: 0;
  }
}

.pi-caret-left,
.pi-chevron-left {
  &:before {
    font-family: var(--font-family-icon) !important;
    content: '\e943' !important;
  }
}
.pi-caret-right,
.pi-chevron-right {
  &:before {
    font-family: var(--font-family-icon) !important;
    content: '\e942' !important;
  }
}

.ag-theme-balham {
  font-weight: 400;
  font-size: 12px;

  .ag-group-contracted .ag-icon.ag-icon-contracted,
  .ag-group-expanded .ag-icon-expanded {
    &:before {
      font-family: var(--font-family-icon) !important;
    }
  }
  .ag-group-contracted .ag-icon.ag-icon-contracted {
    &:before {
      content: '\e942' !important;
      padding-left: 2px;
    }
  }
  .ag-group-expanded .ag-icon-expanded {
    &:before {
      content: '\e938' !important;
    }
  }

  .ag-icon-group {
    background-image: none;
    font-family: var(--font-family-icon) !important;
    font-size: 14px;

    &::before {
      content: '\e924';
    }
  }
  .ag-icon-group .ag-column-drag:hover {
    cursor: move;
  }
  .ag-column-drag:focus,
  .ag-column-drag:active {
    cursor: move;
  }
  .ag-column-drop-cell {
    background-color: var(--grid-tab-bg-color-column-drop-cell);
    border-radius: var(--grid-tab-br-column-drop-cell);
  }

  span.ag-icon {
    &.ag-icon-checkbox-checked:empty {
      background-image: none;
    }
    &.ag-icon-checkbox-unchecked {
      width: 14px;
      height: 16px;
      background-image: none;
      font-family: var(--font-family-icon) !important;
      font-style: normal;
      font-weight: normal;
      top: auto;
      color: var(--grid-tab-ft-color-icon-checkbox-unchecked);
      font-size: 14px;

      &::before {
        background-image: none;
        content: '\e944';
      }
    }
  }

  .ag-ltr .ag-cell-focus {
    border: 1px solid var(--grid-tab-bd-color-ltr-cell-focus);
  }

  .ag-selection-checkbox span,
  span.ag-icon.ag-icon-checkbox-checked {
    margin-right: 0;
  }
  .ag-cell {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
  }
  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }
  // .ag-cell,
  // .ag-header-cell {
  //   border-right: 1px dashed var(--grid-tab-bd-color-cell);
  // }

  .ag-header-cell-resize,
  .ag-header-cell {
    &::after {
      border-right: 0px solid transparent;
      content: '';
      display: none;
    }
  }
}
body .ag-theme-balham {
  /* column search */
  .ag-tool-panel {
    background-color: var(--grid-tab-bg-color-tool-panel) !important;
    border-top: 1px solid var(--grid-tab-bd-color-tool-panel-1) !important;
    border-right: 1px solid var(--grid-tab-bd-color-tool-panel-1) !important;
    border-bottom: 1px solid var(--grid-tab-bd-color-tool-panel-2);
  }
  // .ag-column-panel .ag-pivot-mode,
  // .ag-column-select-header,
  // .ag-column-panel .ag-column-select-panel,
  // .ag-column-panel .ag-column-drop {
  //   border-bottom: 1px solid var(--grid-tab-bd-color-column);
  // }
  .ag-column-name-filter {
    border-radius: var(--grid-tab-br-column-name-filter);
    border: 1px solid var(--grid-tab-bd-color-column-name-filter);
    height: 24px;
  }

  .ag-menu {
    // border: 0px solid var(--grid-tab-bd-color-menu);
    border: 0px solid transparent;
    border-radius: var(--grid-tab-br-menu);
    font-family: var(--font-family);
    color: var(--grid-tab-ft-color-menu);
    box-shadow: var(--grid-tab-bs-menu);
  }
  .ag-tab-header {
    // border-bottom: 0px solid var(--grid-tab-bd-color-tab-header);
    border-bottom: 0px solid transparent;
    font-family: var(--font-family);
  }

  span.ag-icon-expanded {
    background-image: none;
    font-family: var(--font-family-icon);

    &:before {
      content: '\e938';
    }
  }

  .ag-group-expanded .ag-icon-contracted,
  .ag-group-expanded .ag-icon-contracted:empty {
    background-image: none;
    font-family: var(--font-family-icon);

    &:before {
      content: '\e92b';
    }
  }
  .ag-row-selected {
    border-color: var(--ag-row-bd-color-selected);
  }
}

.iupics-blade-content-first.gridCollapsed .ag-theme-balham {
  .ag-cell,
  .ag-header-cell {
    border-right: 0px dashed transparent;
  }
}
.ag-paging-panel,
.ag-paging-page-summary-panel {
  display: flex;
  align-items: center;
}
.ag-theme-balham .ag-paging-panel {
  border-top: 0;
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
  background-color: var(--grid-tab-bg-color-footer);
}
.ag-theme-balham .ag-tab-selected {
  color: var(--ag-header-foreground-color-alt, var(--ag-header-foreground-color));
}
.ag-paging-page-summary-panel span,
.ag-paging-page-summary-panel button {
  margin-right: 2px;
  margin-left: 2px;
}
.ag-paging-row-summary-panel {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 6px;
}
// .ag-theme-balham button.ag-paging-button {
//   display: inline-block;
//   vertical-align: middle;
//   color: var(--grid-tab-bg-color-paging-button);
//   background-color: var(--grid-tab-ft-color-paging-button);
//   padding: 10px 10px;
//   border-radius: var(--grid-tab-br-paging-button);
//   text-indent: 40px;
// }

.pi-chevron-up {
  &:before {
    font-family: var(--font-family-icon) !important;
    content: '\e92b' !important;
  }
}

.pi-chevron-down {
  &:before {
    font-family: var(--font-family-icon) !important;
    content: '\e938' !important;
  }
}

//#region tabview
body .p-tabview {
  border: none;

  .p-tabview-nav {
    padding: 0;
    background: var(--tabview-bg-color-header);

    li {
      border: 1px solid var(--tabview-bd-color-title);
      background-color: var(--tabview-bg-color-title);
      transition: background-color 0.2s;
      margin-right: 2px;
      margin-bottom: -1px;
      border-top-left-radius: var(--tabview-br-title);
      border-top-right-radius: var(--tabview-br-title);

      &:not(.p-highlight):not(.p-disabled):hover {
        background-color: var(--tabview-bg-color-title-hover);
        border: 1px solid var(--tabview-bd-color-title-hover);

        a,
        .p-tabview-close {
          color: var(--tabview-ft-color-title-hover);
        }
      }

      &.p-highlight {
        background-color: var(--tabview-bg-color-title-highlighted);
        color: var(--tabview-ft-color-title-highlighted);

        &:hover {
          background-color: var(--tabview-bg-title-highlighted-hover);

          a {
            color: var(--tabview-ft-title-highlighted-hover);

            .p-tabview-left-icon,
            .p-tabview-right-icon {
              color: var(--tabview-ft-title-highlighted-hover);
            }
          }
        }

        a,
        .p-tabview-close {
          color: var(--tabview-ft-color-title-highlighted);
        }
      }
      &.p-tabview-selected a {
        cursor: pointer;
      }

      a {
        float: none;
        display: inline-block;
        color: var(--tabview-ft-color-title);
        padding: 0.571em 1em;
        font-weight: 400;

        .p-tabview-left-icon {
          margin-right: 0.5em;
        }
        .p-tabview-right-icon {
          margin-left: 0.5em;
        }
      }
      .p-tabview-close {
        color: var(--tabview-ft-color-title-close);
        margin: 0 0.5em 0 0;
        vertical-align: middle;

        &:hover {
          color: var(--tabview-ft-color-title-close-hover);
        }
      }
    }
  }
  .p-tabview-panels {
    background-color: var(--tabview-bg-color-panels);
    padding: 0.571em 1em;
    border: 1px solid var(--tabview-bd-color-panels);
    color: var(--tabview-ft-color-panels);
    margin-top: -1px;
    margin-right: 6px;
    border-radius: var(--tabview-br-panels);

    .p-tabview-panel {
      padding: 0;
    }
  }
}
//#endregion

/* ---  entete  --- */

body .p-card {
  background-color: var(--p-card-bg-color);
  color: var(--p-card-ft-color);
  box-shadow: var(--p-card-bs);

  &.ui-corner-all {
    border-radius: var(--p-card-br-corner-all);
  }
}

body .fc {
  th {
    background-color: var(--calendar-view-ui-bg-color-fc-th);
    border-left: 0;
    border-right: 0;
    border-top: 1px solid var(--calendar-view-ui-bd-color-fc-th);
    border-bottom: 1px solid var(--calendar-view-ui-bd-color-fc-th);
    color: var(--calendar-view-ui-ft-color-fc-th);
    padding: 0.571em 1em;
    font-size: 12px;
    font-weight: normal;
  }
  td {
    background-color: var(--calendar-view-ui-bg-color-fc-td);
    border: 1px solid var(--calendar-view-ui-bd-color-fc-td);
    color: var(--calendar-view-ui-ft-color-fc-td);

    &.fc-sat,
    &.fc-sun {
      background-color: var(--calendar-view-ui-bg-color-fc-td-sat-sun);
    }
    &.fc-head-container {
      border: 1px solid var(--calendar-view-ui-bd-color-fc-head-container);
    }
  }

  .fc-event {
    background-color: var(--calendar-view-ui-bg-color-fc-event);
    border: 1px solid var(--calendar-view-ui-bd-color-fc-event);
    padding: 5px;
    color: var(--calendar-view-ui-ft-color-fc-event);
    font-size: 12px;
  }
}
.fc-ltr .fc-basic-view .fc-day-top.fc-sat .fc-day-number,
.fc-ltr .fc-basic-view .fc-day-top.fc-sun .fc-day-number {
  color: var(--calendar-view-ui-ft-color-fc-ltr-basic-view-day-top-day-number);
}

body .ui-fluid .fc .fc-toolbar .p-button {
  width: auto;
}
body .p-picklist {
  width: 100%;

  .p-picklist-buttons {
    width: 10%;
    padding: 0.571em 1em;

    button {
      font-size: 12px;
      font-family: var(--font-family-icon);
    }
  }
  .p-picklist-header {
    background-color: var(--p-picklist-bg-color-header);
    color: var(--p-picklist-ft-color-header);
    border: 1px solid var(--p-picklist-bd-color-header);
    padding: 0.571em 1em;
    font-weight: 400;
    border-bottom: 0 none;
    text-align: center;
  }
  .p-picklist-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border: 1px solid var(--p-picklist-bd-color-filter-container);
    color: var(--p-picklist-ft-color-filter-container);
    background-color: var(--p-picklist-bg-color-filter-container);
    margin: 0;
    border-bottom: 0 none;

    input {
      width: 100%;
      padding-right: 2em;
    }

    .p-picklist-filter-icon {
      top: 50%;
      margin-top: -0.5em;
      left: auto;
      right: 1.357em;
      color: var(--p-picklist-ft-color-filter-container-icon);
    }
  }
  .p-picklist-list {
    border: 1px solid var(--p-picklist-bd-color-list);
    background-color: var(--p-picklist-bg-color-list);
    color: var(--p-picklist-ft-color-list);
    padding: 0;
    height: 12.5em;
    width: 100%;

    .p-picklist-item {
      padding: 0.429em 0.857em;
      margin: 0;
      border: none;
      color: var(--p-picklist-ft-color-list-item);
      background-color: var(--p-picklist-bg-color-list-item);

      &:not(.p-highlight):hover {
        background-color: var(--p-picklist-bg-color-list-item-hover);
        color: var(--p-picklist-ft-color-list-item-hover);
      }
      &.p-highlight {
        background-color: var(--p-picklist-bg-color-list-item-highlight);
        color: var(--p-picklist-ft-color-list-item-highlight);
      }
    }
  }
}
/* Select order */
.p-picklist-listwrapper {
  width: 40%;
}
/* Select order */

@media (max-width: 40em) {
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: 0.571em 1em;
  }
}

body .p-treetable {
  &.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
    cursor: pointer;
    background-color: var(--p-treetable-bg-color-hoverable-rows-tbody-tr-hover);
    color: var(--p-treetable-ft-color-hoverable-rows-tbody-tr-hover);
  }

  .p-treetable-caption,
  .p-treetable-summary {
    background-color: var(--p-treetable-bg-color-caption-and-summary);
    color: var(--p-treetable-bd-color-caption-and-summary);
    border: 1px solid var(--p-treetable-ft-color-caption-and-summary);
    padding: 0.571em 1em;
    text-align: center;
  }
  .p-treetable-caption {
    border-bottom: 0 none;
    font-weight: 700;
  }
  .p-treetable-summary {
    border-top: 0 none;
    font-weight: 700;
  }
  .p-treetable-thead > tr > th {
    padding: 0.571em 0.857em;
    border: 1px solid var(--p-treetable-bd-color-thead-tr-th);
    font-weight: 700;
    color: var(--p-treetable-ft-color-thead-tr-th);
    background-color: var(--p-treetable-bg-color-thead-tr-th);
  }
  .p-treetable-tbody > tr {
    background: var(--p-treetable-bg-color-tbody-tr);
    color: var(--p-treetable-ft-color-tbody-tr);

    & > td {
      background: var(--p-treetable-bg-color-tbody-tr-td, inherit);
      border: 1px solid var(--p-treetable-bd-color-tbody-tr-td);
      padding: 0.429em 0.857em;

      .p-treetable-toggler {
        color: var(--p-treetable-ft-color-tbody-tr-td-toggler);
        vertical-align: middle;
      }
      .p-treetable-chkbox {
        vertical-align: middle;
        margin-right: 0.5em;
      }
    }
    &.p-highlight {
      background-color: var(--p-treetable-bg-color-tbody-tr-highlight);
      color: var(--p-treetable-ft-color-tbody-tr-highlight);

      & > td {
        background: var(--p-treetable-bg-color-tbody-tr-td-highlight, inherit);
        border: 1px solid var(--p-treetable-bd-color-tbody-tr-td-highlight);

        .p-treetable-toggler {
          color: var(--p-treetable-ft-color-tbody-tr-td-toggler-highlight);
        }
      }
    }
    &.p-contextmenu-selected {
      background-color: var(--p-treetable-bg-color-tbody-tr-contextmenu-selected);
      color: var(--p-treetable-ft-color-tbody-tr-contextmenu-selected);

      & > td {
        background: var(--p-treetable-bg-color-tbody-tr-contextmenu-td-selected, inherit);
        border: 1px solid var(--p-treetable-bd-color-tbody-tr-contextmenu-td-selected);

        .p-treetable-toggler {
          color: var(--p-treetable-ft-color-tbody-tr-contextmenu-td-toggler-selected);
        }
      }
    }
  }
  .p-treetable-tfoot > tr > td {
    padding: 0.571em 0.857em;
    border: 1px solid var(--p-treetable-bd-color-tfoot-tr-td);
    font-weight: 700;
    color: var(--p-treetable-ft-color-tfoot-tr-td);
    background-color: var(--p-treetable-bg-color-tfoot-tr-td);
  }
  .p-sortable-column {
    &:not(.p-highlight):hover {
      background-color: var(--p-treetable-bg-color-sortable-column-hover);
      color: var(--p-treetable-ft-color-sortable-column-hover);

      a,
      a:hover {
        color: var(--p-treetable-ft-color-sortable-column-link-hover);
      }
    }
    &.p-highlight {
      background-color: var(--p-treetable-bg-color-sortable-column-highlight);
      color: var(--p-treetable-ft-color-sortable-column-highlight);

      a,
      a:hover {
        color: var(--p-treetable-ft-color-sortable-column-link-highlight);
      }
    }

    a,
    a:hover {
      color: var(--p-treetable-ft-color-sortable-column-link);
    }
  }
  .p-column-resizer-helper {
    background-color: var(--p-treetable-bg-color-column-resizer-helper);
  }
}

/* -------- p-toast info dialog & notifications ------------ */
body .p-toast {
  width: 80%;
  @media screen and (min-width: 980px) {
    width: 20%;
  }
  // z-index: 7999 !important;

  h3 {
    background-color: var(--p-toast-bg-color-h3);
    padding: 0;
  }

  .p-toast-message {
    box-shadow: var(--p-toast-bs-message);
    margin: 0 0 1em 0;
    border-radius: var(--p-toast-br-message);
    background-color: var(--p-toast-bg-color-message);
    color: var(--p-toast-ft-color-message);

    &.p-toast-message-info {
      background-color: var(--p-toast-bg-color-message-info);
      border: none;
      color: var(--p-toast-ft-color-message-info);
    }
    .p-toast-icon-close {
      color: var(--p-toast-ft-color-message-icon-close);
      right: 10px;
      top: 10px;
      position: absolute;

      & .pi-times {
        font-family: var(--font-family-icon);
        font-size: 12px;

        &::before {
          content: '\e941';
        }
      }
    }
    &.p-toast-message-success {
      background-color: var(--p-toast-bg-color-message-success);
      border: none;
      color: var(--p-toast-ft-color-message-success);

      .p-toast-icon-close-icon {
        color: var(--p-toast-ft-color-message-success-close-icon);
      }
    }
    &.p-toast-message-warn {
      background-color: var(--p-toast-bg-color-message-warn);
      border: 1px solid var(--p-toast-bd-color-message-warn);
      color: var(--p-toast-ft-color-message-warn);

      .p-toast-icon-close-icon {
        color: var(--p-toast-ft-color-message-warn-close-icon);
      }
    }
    &.p-toast-message-error {
      background-color: var(--p-toast-bg-color-message-error);
      border: 3px solid var(--p-toast-bd-color-message-error);
      color: var(--p-toast-ft-color-message-error);

      h3 {
        color: var(--p-toast-ft-color-message-error-h3);
      }

      .p-toast-icon-close-icon {
        color: var(--p-toast-ft-color-message-error-close-icon);
      }
    }
    .p-col-12 button.p-button {
      width: 50%;
      margin-left: 50%;
    }
  }
  .p-toast-message-content {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: initial;
  }
}

/* chips */

body .p-chips-token-icon.pi-time:before {
  content: 'e941';
  font-family: var(--font-family-icon) !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-chips-input-token {
  vertical-align: unset;
}

body .ui-overlaypanel {
  background-color: var(--ui-overlaypanel-bg-color);
  color: var(--ui-overlaypanel-ft-color);
  padding: 0;
  border: 0px solid var(--ui-overlaypanel-bd-color);

  &:before {
    border-color: var(--ui-overlaypanel-bd-color-before-1);
    border-bottom-color: var(--ui-overlaypanel-bd-color-before-2);
  }
  &:after {
    border-color: var(--ui-overlaypanel-bd-color-after-1);
    border-bottom-color: var(--ui-overlaypanel-bd-color-after-2);
  }

  &.ui-overlaypanel-flipped {
    &:before {
      border-top-color: var(--ui-overlaypanel-bd-color-flipped-before);
    }
    &:after {
      border-top-color: var(--ui-overlaypanel-bd-color-flipped-after);
    }
  }

  .ui-overlaypanel-content {
    padding: 0.571em 1em;
  }
  .ui-overlaypanel-close {
    background-color: var(--ui-overlaypanel-bg-color-close);
    color: var(--ui-overlaypanel-ft-color-close);
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: -0.769em;
    right: -0.769em;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--ui-overlaypanel-bg-color-close-hover);
      color: var(--ui-overlaypanel-ft-color-close-hover);
    }

    .ui-overlaypanel-close-icon {
      line-height: inherit;
    }
  }
}
.p-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em;
}
.search-container .p-sidebar-header {
  padding: 0;
}

body .p-sidebar {
  background-color: var(--p-sidebar-bg-color);
  color: var(--p-sidebar-ft-color);
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  border-left: 1px solid var(--p-sidebar-bd-color);
  box-shadow: var(--p-sidebar-bs);
  &-top:not(.search-container) {
    .p-sidebar-content {
      box-shadow: var(--p-sidebar-bs-top-content);
    }
  }

  .p-sidebar-close {
    display: initial;
    color: var(--p-sidebar-ft-color-close);

    &:hover {
      color: var(--p-sidebar-ft-color-close-hover);
    }
  }
}
body .p-tooltip {
  // position: absolute;
  // display: none;
  // padding: 0.25em 0.5em;
  max-width: 222.5em;
  // TODO: fix z-index
  z-index: 99999 !important;

  &.p-tooltip-right,
  &.p-tooltip-left,
  &.p-tooltip-top,
  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-left-color: var(--p-tooltip-bd-color-arrow);
    }
  }
  .p-tooltip-text {
    display: flex;
    flex-direction: column;
    background-color: var(--p-tooltip-bg-color-text);
    color: var(--p-tooltip-ft-color-text);
    padding: 0.429em;
    max-width: 40em;
    -webkit-box-shadow: var(--p-tooltip-bs-text);
    -moz-box-shadow: var(--p-tooltip-bs-text);
    box-shadow: var(--p-tooltip-bs-text);

    .tooltip-description {
      font-weight: bold;
    }
    .tooltip-help {
      opacity: 0.75;
    }
  }
}

body .ui-breadcrumb {
  padding: 10px 15px;

  ul li {
    &:first-child a {
      color: var(--prime-breadcrumb-ft-color-list-item-first-child-link);
      margin: 0;
    }

    &.ui-breadcrumb-chevron {
      margin: 0 0.5em 0 0.5em;
      color: var(--prime-breadcrumb-ft-color-list-item-chevron);
      width: 6px;
      height: 11px;
      display: inline-block;
      font-size: 10px;
      padding-top: 1px;
    }

    .p-menuitem-link {
      color: var(--prime-breadcrumb-ft-color-list-item-menuitem-link);
      margin: 0;
    }
    .p-menuitem-icon {
      color: var(--prime-breadcrumb-ft-color-list-item-menuitem-icon);
    }
  }
}

body .ag-theme-balham .ag-header-cell {
  border-right: 0;
}
.p-toolbar-group-right,
.p-toolbar-group-left {
  height: 100%;
}

p-toolbar.toolbar.open .p-toolbar {
  height: 35px;
}
p-toolbar.toolbar.closed .p-toolbar {
  height: 2px;
}
p-toolbar.toolbar.closed .p-toolbar .p-toolbar-group-right,
.p-toolbar-group-left {
  height: 0px;
}

.ag-theme-balham .ag-tool-panel .ag-side-buttons button {
  width: 62px;
}
.ag-tool-panel .ag-side-buttons {
  min-height: 62px;
  height: 62px;
  background-color: var(--grid-tab-bg-color-side-buttons);
  border-right: 0;
}
body .iupics-blade-content-first .ag-theme-balham .ag-tool-panel {
  border-right: 0;
}
.ag-root-wrapper.ag-layout-normal .ag-theme-balham .ag-tool-panel {
  min-height: 63px;
}
.ag-root-wrapper.ag-layout-normal .ag-layout-normal.ag-root-wrapper-body {
  min-height: 63px;
}
.ag-root-wrapper-body .ag-root.ag-layout-normal {
  width: 1px;
}
.iupics-blade-content-first.gridCollapsed .ag-paging-panel .ag-paging-row-summary-panel {
  display: none;
}
body .ag-theme-balham .ag-cell {
  // border-right: 0px dashed var(--grid-tab-bd-color-cell);
  &:hover {
    cursor: pointer;
  }
}

/* -------------------- icon button ----------------------- */

button.p-button.p-button-icon-only span {
  /* --  group-check search group -- */
  &.p-button-icon-left.icon-group-check {
    margin-top: -3px;
    font-size: 14px;
  }
  &.icon-advanced-search-2 {
    font-size: 14px;
  }

  /* --  favorite -- */
  .p-button-icon-left.icon-favorite {
    margin-top: -8px;
    margin-left: -8px;
    font-size: 15px;
  }
}

/*-- ico menu hamburger --*/
.wd-menu-top-show button.p-button.p-button-icon-only span.icon-menu-bars.p-button-icon-left {
  width: 12px;
  font-size: 11px;
}
/* entete fenêtre */
.wd-workspace {
  width: 100%;

  .p-toolbar-group-right,
  .p-toolbar-group-left {
    height: 100%;
    margin-top: 0;
  }

  .p-toolbar {
    display: flex;
    flex-wrap: nowrap;
    height: 35px;
  }
}

@media screen and (max-width: 480px) {
  .wd-workspace .p-toolbar,
  p-toolbar.toolbar.open .p-toolbar {
    height: auto;
  }
}
.wd-workspace .p-toolbar .ui-center,
.iu-specific-window h1 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  font-size: 12px;
}
.wd-workspace .p-toolbar .ui-center {
  color: var(--menu-bar-detail-ui-ft-color);
}
/* ------ blade ------------- */

body .iupics-dialog-titlebar .p-button.p-button-icon-only {
  width: 24px;
}

button.p-button.p-button-icon-only span.p-button-icon-left.icon-undo {
  margin-left: -9px;
}

/* -- search -- */
// button.p-button.p-button-icon-only span.p-button-icon-left.icon-advanced-search {
//   margin-top: -8px;
//   margin-left: -7px;
//   font-size: 15px;
// }

/*-- cdk kanban style --*/
.cdk-board-list {
  width: 100%;
  height: 100%;
  .cdk-virtual-scroll-content-wrapper {
    flex: 0 1 auto;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    margin-top: 0;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
  }
}

.cdk-board-list-flex {
  width: 100%;
  height: 100%;
  .cdk-virtual-scroll-content-wrapper {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
  }
}

.p-button-icon-only .icon-add, /*-- new --*/
.p-button-icon-only .icon-export, /*-- export --*/ 
.p-button-icon-only .icon-save3x, /*-- save --*/
.p-button-icon-only .icon-copy /*-- copy --*/ {
  font-size: 13px;
}

.p-button-icon-only .icon-print, /*-- print --*/
.p-button-icon-only .icon-delete, /*-- delete --*/
.p-button-icon-only .icon-attach3x /*-- attach --*/ {
  font-size: 14px;
}

/*-- refresh -- */
.p-button-icon-only .icon-refresh3x {
  font-size: 15px;
}

/*-- mail-envelope --*/
.p-button-icon-only .icon-icon-mail {
  font-size: 11px;
}

/*-- filtrer filter --*/
body button.p-button.p-button-icon-only span.p-button-icon-left.pi-filter {
  width: 15px;
  height: 10px;
  margin-top: -5px;
  margin-left: -8px;
}

article.accordion section {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -ms-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}
/*-- search 2eme toolbar--*/
body button.p-button.p-button-icon-only span.p-button-icon-left.fa-search {
  width: 14px;
  height: 14px;
  margin-top: -7px;
  margin-left: -7px;
}
.p-toolbar-group-right.close {
  margin-top: 0px;
  height: 12px;

  button.p-button.p-button-icon-only {
    padding: 0;
    width: 10px;
    line-height: 12px;
    height: 12px;
  }
}
span.p-button-text.ui-clickable {
  padding: 0;
}

body article.accordion h2 a.p-button {
  border: 0 !important;
  padding-top: 1px;
  cursor: pointer;
}

/* ---- table sidebar N+1 (cfr acces role) ---- */

button.p-button.p-button-icon-only.p-button-table {
  background-color: var(--p-button-bg-color-icon-only-table);
}
button.p-button.p-button-icon-only.p-button-table span.p-button-icon-left.icon-table {
  color: var(--p-button-bg-color-icon-only-table-icon-left-icon-table);
}

/* ---- calendar ---- */
.icon-calendar::before {
  font-size: 13px;
}

/* ---- graph ---- */
.icon-graph-smart,
.icon-graph-smart::before {
  font-size: 13px;
}

/* form */

fieldset legend {
  &.singleRow .p-fieldset-legend-text {
    width: calc(95% - 24px);
  }

  .pi-minus {
    width: 24px;
    height: 24px;
    display: inline-block;
    font-family: var(--font-family-icon);

    &::before {
      content: '\e92b';
    }
  }
  .pi-plus {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-family: var(--font-family-icon);

    &::before {
      content: '\e938';
    }
  }
  .p-fieldset-legend-text {
    font-size: 13px;
    width: 95%;
    display: inline-block;
  }
}

.iupics-blade-content label,
.p-autocomplete label,
.p-calendar label,
.input-file-ui label,
.iu-input-chips-ui label,
.iu-input-text-ui label,
.specificModal label,
iu-input-number-ui label {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--global-label-ft-color-1);
  margin-bottom: 5px;
  display: block;
}
div.filtres .iu-input-chips-ui {
  cursor: auto;
  & label {
    display: block;
  }

  & div.p-chips {
    width: 100%;
  }
  ul.p-inputtext {
    height: 26px;
  }
}

/* Inputs */
.p-inputtext:enabled:hover:not(:read-only):not(.ui-state-error) {
  & + i,
  & + i + i {
    border-color: var(--input-bd-color-icon-hover);
  }
}
body .p-inputtext:enabled:focus:not(:read-only):not(.ui-state-error) + i {
  border-color: var(--input-bd-color-icon-focus);
}

textarea:enabled:hover:not(:read-only):not(.ui-state-error) + i {
  border-color: var(--input-bd-color-icon-hover);
}
textarea:enabled:focus:not(:read-only):not(.ui-state-error) + i {
  border-color: var(--input-bd-color-icon-focus);
}

i.rtconflict {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  float: right;
  font-size: 1.1em;
  cursor: pointer;
  position: relative;
  right: 0.3em;
  margin-top: -1.4em;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

i.icon-search-bolder {
  color: var(--input-ft-color-icon);
  background-color: var(--input-bg-color-icon);
  border: 1px solid var(--input-bd-color-icon);
  border-top-right-radius: var(--input-br-icon);
  border-bottom-right-radius: var(--input-br-icon);
  width: 26px;
  height: 26px;
  border-left: 0;
  float: right;

  &:hover {
    background-color: var(--input-bg-color-icon-hover);
    cursor: pointer;
  }
}
span > input.p-inputtext:read-only {
  background-color: var(--input-bg-color-disabled);
  border: 1px solid var(--input-bd-color-disabled);
}
input.p-inputtext:read-only + i.icon-search-bolder {
  background-color: var(--input-bg-color-disabled);
  border: 1px solid var(--input-bd-color-disabled);
  border-left: 0;
}

li.searchSC input.p-inputtext.searchInput:focus:not(:read-only) + i.icon-search-bolder,
input.p-inputtext:focus:not(:read-only) + i.icon-search-bolder {
  border: 1px solid var(--input-bd-color-icon-focus);
  border-left: 0;
}

body .p-autocomplete-loader.icon-line-loader {
  border: 0 !important;
  font-family: var(--font-family-icon);
  font-size: 24px;
  position: absolute;
  display: inline;
  transform: translateX(-25px) translateY(1px);
}
body .defaultDateCalendar.p-col-12 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/* -------------------- button ----------------------- */
// .iupics-blade-content iu-button-ui .p-button {
//   border-radius: var(--iupics-blade-br-content-p-button);
//   border: 1px solid var(--iupics-blade-bd-color-content-p-button);
//   background-color: var(--iupics-blade-bg-color-content-p-button);
//   color: var(--iupics-blade-ft-color-content-p-button);
// }

button.p-button {
  gap: 0.4em;
  margin: 0;
  min-height: 24px;
  color: var(--button-ft-color);
  background-color: var(--button-bg-color);
  border: 1px solid var(--button-bd-color);
  border-radius: var(--button-br);
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:enabled:hover {
    background-color: var(--button-bg-color-hover);
    color: var(--button-ft-color-hover);
  }
  &:disabled {
    background-color: var(--button-bg-color-inactive);
    border-color: var(--button-bd-color-inactive);
  }

  &.p-button-icon-only {
    gap: 0;
    width: 24px;
    line-height: 24px;
    height: 24px;
  }

  &.p-button-default {
    background-color: var(--button-bg-color);
    border-color: var(--button-bd-color);
    color: var(--button-ft-color);

    &:enabled:not(.iu-btn-disabled) {
      &:hover {
        background-color: var(--button-bg-color-hover);
        border-color: var(--button-bd-color-hover);
        color: var(--button-ft-color-hover);
      }
      &:active {
        background-color: var(--button-bg-color-active);
        border-color: var(--button-bd-color-active);
        color: var(--button-ft-color-active);
      }
      &:focus {
        background-color: var(--button-bg-color-focus);
        border-color: var(--button-bd-color-focus);
        color: var(--button-ft-color-focus);
      }
    }

    &.no-border {
      border: 0;

      &:hover,
      &:active,
      &:focus {
        border: 0;
      }
    }
    &.no-background {
      background-color: transparent;
      color: var(--button-ft-color);

      &:enabled:not(.iu-btn-disabled) {
        &:hover {
          background-color: transparent;
          color: var(--button-ft-color-hover);
        }
        &:active {
          background-color: transparent;
          color: var(--button-ft-color-active);
        }
        &:focus {
          background-color: transparent;
          color: var(--button-ft-color-focus);
        }
      }
    }

    &:disabled,
    &.iu-btn-disabled {
      background-color: var(--button-bg-color-inactive);
      border-color: var(--button-bd-color-inactive);
      color: var(--button-ft-color-inactive);
    }
  }

  $types: primary, secondary, success, info, warning, alert;
  @each $type in $types {
    &.p-button-#{$type} {
      font-family: var(--font-family);
      line-height: 22px;
      background-color: var(--#{$type}-bg-color);
      border-color: var(--#{$type}-bd-color);
      color: var(--#{$type}-ft-color-alt);

      &:enabled:not(.iu-btn-disabled) {
        &:hover {
          background-color: var(--#{$type}-bg-color-hover);
          border-color: var(--#{$type}-bd-color-hover);
          color: var(--#{$type}-ft-color-alt);
        }
        &:active {
          background-color: var(--#{$type}-bg-color-active);
          border-color: var(--#{$type}-bd-color-active);
          color: var(--#{$type}-ft-color-alt-active);
        }
        &:focus {
          background-color: var(--#{$type}-bg-color-focus);
          border-color: var(--#{$type}-bd-color-focus);
          color: var(--#{$type}-ft-color-alt-focus);
        }
      }

      &.no-border {
        border: 0;

        &:hover,
        &:active,
        &:focus {
          border: 0;
        }
      }
      &.no-background {
        background-color: transparent;
        color: var(--#{$type}-ft-color);

        &:enabled:not(.iu-btn-disabled) {
          &:hover {
            background-color: transparent;
            color: var(--#{$type}-ft-color-hover);
          }
          &:active {
            background-color: transparent;
            color: var(--#{$type}-ft-color-active);
          }
          &:focus {
            background-color: transparent;
            color: var(--#{$type}-ft-color-focus);
          }
        }
      }

      &:disabled,
      &.iu-btn-disabled {
        opacity: 0.5;
      }

      span.p-button-icon-left {
        padding-right: 3px;
      }

      &.margin {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

/* -------------   input text   ------------------ */
// .iupics-blade-content input.p-inputtext {
//   background-color: var(--iupics-blade-ft-color-content-p-inputtext);
// }
body textarea.p-disabled {
  resize: none;
}
body input.p-inputtext:read-only,
body textarea.p-disabled {
  background-color: var(--input-bg-color-disabled);
  border: 1px solid var(--input-bd-color-disabled);
  color: var(--input-ft-color-disabled);
}

body input.p-inputtext:read-only + i.icon-search-bolder,
body input.p-inputtext:read-only + i.coneCalendar,
body input.p-inputtext:read-only + i.iconeLocation,
body iu-calendar-ui span.p-calendar.p-calendar-w-btn input.p-inputtext:read-only + i.iconeCalendar {
  background-color: var(--input-bg-color-disabled);
  color: var(--input-ft-color-disabled);
  border: 1px solid var(--input-bd-color-disabled);
  border-left: 0;
  cursor: default;
}

/* switch */
/* .p-inputswitch .ng-touched {
  background-color: var(--p-inputswitch-ng-touched) !important;
} */

.iupics-blade-content span.undefined {
  background-color: var(--blade-bg-color-content-undefined) !important;
}

/* ------------- spinner increment nombre -------------  */
input {
  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
}

/* ------------- textarea -------------  */
textarea {
  &:enabled {
    &:hover {
      border-color: var(--input-bd-color-hover);
    }
    &:focus {
      border-color: var(--input-bd-color-focus);
    }
  }
  &.iu-field {
    border: 1px solid var(--input-bd-color);
    border-radius: var(--input-br);
    background-color: var(--input-bg-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    height: auto;
    word-break: normal;
  }
}

body .blade-container .p-scrollpanel .p-scrollpanel-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 1;
  float: left;
  @media only screen {
    height: 100%;
  }
  @media (orientation: landscape) and (max-device-height: 640px) {
    height: 100%;
  }
  @media (max-device-width: 640px) {
    height: 100%;
  }
}

body .blade-container .p-scrollpanel .p-scrollpanel-bar-y {
  z-index: 2;
}

body .iupics-blade-content .iupics-blade-content-sidebar {
  /* -- liste N+1 -- */
  .compContainer {
    height: calc(100% - 0px);
    height: 100%;

    /* tiers ou scrollpanel sans stepper */
    .ag-body-container {
      width: 100% !important;
    }
  }
}

/* ------------------    message    ------------------------ */
body .iu-banner-message .iu-banner-message-text {
  display: inline-block;
  width: 90%;
  padding: 8px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  margin: 1px 0;
  font-size: 12px;
  text-align: left;
}
.iu-banner i.icon-close {
  width: 30px;
  right: 5px;
  top: 10px;
  position: absolute;
}
body .iupics-blade-content div.iu-success,
body .iupics-blade-content div.iu-error,
body div.iu-error {
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

/* ---------------- kanban ---------------------------- */
body .boards-list .board-title-text,
body h3.board-title {
  margin-right: auto;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: var(--kanban-ui-ft-color-board-title);
  border-bottom: 0px;
  background-color: var(--kanban-ui-bg-color-board-title);
}

.iupics-blade-content-sidebar-content .ag-theme-balham .ag-root {
  border: 0px solid transparent !important;
}
.iupics-blade-content-sidebar-content.visible span.group .group-list.ui-overlaypanel {
  right: auto;
}

.iupics-blade-content-sidebar .ag-tool-panel {
  display: none;
}

body .p-sidebar-close span.pi.pi-times {
  width: 11px;
  height: 11px;
  font-family: var(--font-family-icon);

  &::before {
    content: '\e942';
  }
}
body .p-sidebar .p-sidebar-close:hover,
body .p-sidebar-close span.pi.pi-times:hover {
  color: var(--pi-pi-times-ft-color-hover);
}

.dataHidden {
  display: none;
}

.autocomplete-universal-filter {
  margin: 5px 0;
  display: flex;
  flex-direction: row-reverse;
  & button {
    border: 0;
  }
}

.pi-angle-right,
.pi-angle-left,
.pi-angle-up,
.pi-angle-down,
.pi-angle-double-down,
.pi-angle-double-up,
.pi-angle-double-left,
.pi-angle-double-right {
  font-family: var(--font-family-icon) !important;
}
.pi-angle-double-down,
.pi-angle-double-up,
.pi-angle-double-left,
.pi-angle-double-right {
  font-size: 11px;
}
.pi-angle-right::before {
  content: '\e942';
}
.pi-angle-left::before {
  content: '\e943';
}
.pi-angle-up::before {
  content: '\e92b';
}
.pi-angle-down::before {
  content: '\e938';
}
.pi-angle-double-down::before,
.pi-angle-double-up::before,
.pi-angle-double-left::before,
.pi-angle-double-right::before {
  content: '\e939';
  width: 12px;
  height: 12px;
  text-align: center;
}
.pi-angle-double-up {
  transform: rotate(180deg);
}
.pi-angle-double-left {
  transform: rotate(90deg);
}
.pi-angle-double-right {
  transform: rotate(-90deg);
}

/* User profile */
.user-profile-sidebar {
  .p-fieldset legend a {
    pointer-events: none;
  }
}
/* User profile */

.iupics-hover-item {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.p-autocomplete-with-label {
  top: 45px !important;
}

/* -----------------  styles.scss  ---------------- */
/* Spinner */
.loader-spinner {
  z-index: 10000;
  position: absolute;
  bottom: 30px;
  right: 30px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: var(--loader-spinner-br);
    box-shadow: var(--loader-spinner-bs);
    background-color: var(--loader-spinner-bg-color);
    animation: spin-animation 1s infinite linear;

    i.icon-spinner {
      font-size: 1.5em;
      color: var(--loader-spinner-ft-color);
    }
  }
}

/* ----- Tab Split View ---  */
.viewSplittedTop,
.viewSplittedBottom {
  position: relative;

  &.viewLeft,
  &.viewRight {
    .panelLeft {
      min-width: 155px;
    }
  }
}
.viewSplittedTop {
  &.viewLeft {
    -webkit-box-shadow: var(--view-splitted-bs-top-left);
    -moz-box-shadow: var(--view-splitted-bs-top-left);
    box-shadow: var(--view-splitted-bs-top-left);
  }
  &.viewRight {
    -webkit-box-shadow: var(--view-splitted-bs-top-right);
    -moz-box-shadow: var(--view-splitted-bs-top-right);
    box-shadow: var(--view-splitted-bs-top-right);
  }
}
.viewSplittedBottom {
  -webkit-box-shadow: var(--view-splitted-bs-bottom);
  -moz-box-shadow: var(--view-splitted-bs-bottom);
  box-shadow: var(--view-splitted-bs-bottom);

  &.viewLeft {
    -webkit-box-shadow: var(--view-splitted-bs-bottom-left);
    -moz-box-shadow: var(--view-splitted-bs-bottom-left);
    box-shadow: var(--view-splitted-bs-bottom-left);
  }
  &.viewRight {
    -webkit-box-shadow: var(--view-splitted-bs-bottom-right);
    -moz-box-shadow: var(--view-splitted-bs-bottom-right);
    box-shadow: var(--view-splitted-bs-bottom-right);
  }
}
/* ----- Tab Split View ---  */
.ui-left {
  width: auto;
  float: left;
  margin-left: 5px;
}
.ui-center {
  margin: auto;
  text-align: center;
  margin-top: 0.3em;
}
.iupics-blade-content-sidebar-content.visible .p-toolbar {
  display: flex;
  width: auto;
  flex-direction: row-reverse;
}

.iu-field {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  background-color: var(--iu-field-bg-color);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--iu-field-bd-color);
}
.fa-fw {
  width: 1.4em !important;
  text-align: center;
}
.input-field {
  width: 100%;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon.pi-check,
body .ag-theme-balham span.ag-icon.ag-icon-checkbox-checked {
  width: calc(var(--checkbox-ft-size) + 2px) !important;
  height: calc(var(--checkbox-ft-size) + 2px) !important;
  background-image: none;
  font-family: var(--font-family-icon) !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  -moz-osx-font-smoothing: grayscale;

  top: auto;
  color: var(--checkbox-ft-color);
  font-size: var(--checkbox-ft-size) !important;
}

.ag-theme-balham .ag-icon-checkbox-checked:empty,
body .p-checkbox .p-checkbox-box .p-checkbox-icon.pi-check,
.ag-theme-balham span.ag-icon.ag-icon-checkbox-checked {
  &::before {
    content: '\e918';
    background-image: none;
  }
}

body .p-checkbox .p-checkbox-box.p-disabled span.ag-icon.ag-icon-checkbox-unchecked,
body .p-checkbox .p-checkbox-box.p-disabled .p-checkbox-icon.pi-check {
  color: var(--checkbox-ft-color-disabled-checkbox-icon);
}
body .ag-cell-wrapper {
  align-items: center;
}

/*css pour les boutons*/
.specificModal {
  .iu-specific-btn-container {
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    &.shouldAddPadding {
      padding-top: 0;
    }
  }
}

.compContainer {
  .p-col {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
  iu-grid-ui {
    .p-col-1,
    .p-col-2,
    .p-col-3,
    .p-col-4,
    .p-col-5,
    .p-col-6,
    .p-col-7,
    .p-col-8,
    .p-col-9,
    .p-col-10,
    .p-col-11,
    .p-col-12 {
      padding: 0.3em 0.5em;
    }
  }
}
.iupics-specific-blade-content {
  .compContainer {
    .contentInline {
      @media screen and (min-width: 475px) {
        width: 72em !important;
      }
    }
    .p-col-12,
    .p-fieldset,
    .contentInlineScrollPanel.p-col {
      padding: 0;
    }
    .p-col-12 .p-col-12 .p-col-12 .p-col-12 {
      padding: 0.3em var(--window-margin-value);
    }
    .p-fieldset {
      min-width: 0;
    }
  }
}

// icons
.icon-excel::before {
  color: var(--icon-ft-color-excel) !important;
}
.icon-word::before {
  color: var(--icon-ft-color-word) !important;
}
.icon-html::before {
  color: var(--icon-ft-color-html) !important;
}
.icon-pdf::before {
  color: var(--icon-ft-color-pdf) !important;
}
.icon-notes {
  font-size: 1.35rem;
}

//fix https://github.com/primefaces/primeng/issues/10682
.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
  @media screen and (max-width: 640px) {
    display: none;
  }
}
.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  button.close-window {
    font-size: 20px;
    font-weight: bold;
    text-align: start;

    &:hover {
      cursor: pointer;
    }
  }

  .blade-container {
    padding-top: 0;
  }
}

/* || Animations */

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
//fix https://github.com/primefaces/primeng/issues/10682

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
